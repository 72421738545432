@media (max-width: 800px) {
    .hero-heading {
      font-size: 2em;
    }
    .circle-container {
        position: relative;
        width: 100px;
        height: 100px;
        cursor: pointer;
      }
      .hero-animation-container {
        height: 100px;
        width: 100px;
      
        margin-top: 50px;
        margin-bottom: 50px;
      }
  
      .start-homepage-interview-right{
        position: fixed;
        display: none;
        right: 30px;
        font-size: 0.9em;
        padding: 10px;
        padding-right: 20px;
        padding-left: 20px;
        bottom: 30px;
        border-radius: 30px;
        border: solid rgba(222, 193, 253, 0.3) 1px;
      
        font-family: 'Open Sans', sans-serif;
        background: linear-gradient(to top, rgba(112, 60, 240, 0.2), transparent 30%), linear-gradient(to bottom, rgba(112, 60, 240, 0.2), transparent 30%);
        color: #DEC1FD;
        cursor: pointer;
      }
  
      .homepage-simple-button{
        display: none;
      }
  
      .homepage-signup-topic{
        font-size: 1.5em;
      }
      .signupform {
        width: 80vw;
        margin-left: 10vw;
        margin-top: 50px;
      
        outline: none;
        padding: 15px;
        border: solid rgba(222, 193, 253, 0.3) 1px;
        border-radius: 10px;
        background-color: transparent;
        color: #DEC1FD;
        font-family: 'Open Sans', sans-serif;
        background:
      
          linear-gradient(to top, rgba(112, 60, 240, 0.2), transparent 30%);
      
      
      }
  }