.review-header{
    padding: 20px;
    width:100vw;
    border-bottom: solid 1px #eee;
    font-weight: 500;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}
.back-home{
    font-weight: 400;
    color: #aaa;
    cursor: pointer;
}
.back-home:active{
    transform: scale(0.98);
}
.back-home:hover{
    color: #333;
}
.dialogue-role{
    font-weight: 500;
    color: #333;

}

.feedback-button{
    padding: 10px;
    background-color: #703CF0;
    color: white;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
}
.feedback-button:active{
    transform: scale(0.98);
}
.transcript-metric-value{
    color: #703CF0;
    
}
.feedback-content{
    line-height: 2em;
    color: black;
    flex-grow: 1;
}
.transcript-metric-label{
    color: #aaa;
}
.transcript-metrics{
    display: flex;
    padding-bottom: 20px;

}
.transcript-metric{
    display: flex;
    font-size: 0.9em;
    font-weight: 400;
    margin-right: 20px;
}
.transcript-metric-value{
    margin-right: 5px;
    
}

.transcript-title{
    margin-bottom: 10px;
}
.magic-want-retry{
height: 20px;
   width: 20px;
   margin-top: 2px;
    background-image: url("../assets/wand.png");
    background-size: cover;
    margin-right: 5px;
}
.feedback-title{
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
    font-size: 1.3em;
}

.sidecoach{
    position: fixed;
    left: 50px;
    top:120px;
    width:200px;

    height: calc(100vh - 140px);
}
.sidecoach-bubble{
    scale: 0.7;
}
.sidecoach-top-line{
    width: 50%;
    height:calc(50% - 150px);
    border-right: dashed 2px #eee;

}
.sidecoach-bottom-line{
    width: 50%;
    height:calc(50% - 150px);
    border-right: dashed 2px #eee;

}

.feedback-content{
    flex-grow: 1;
    margin-bottom: 10px;
    overflow-y: scroll;
}
.feedback-box{

    position: fixed;
    right: 20px;
    top: 120px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    
    

}

.feedback-content-shimmer{
    background-color: #eee;
    width: 300px;
    height: 40px;
    margin-top: 20px;
}

.bg-gray{
    background-color: #f6f6f6;
}

.shimmer-block{
    width: 600px;
    height: 80px;
    margin-bottom: 20px;

}

.interview-video-shimmer{
    height: 200px;
    width: 350px;
}
.uploading-video-text{
    width: 100%;
    padding-top: 80px;
    text-align: center;
    color: #703CF0;
}

.uploading-video-subtext{
    width: 100%;
    color: #aaa;
    text-align: center;
    font-size: 0.8em;
}

.review-holder-main{
    overflow-y: scroll;
    max-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    
    

}
.assistant-for-review{

    text-decoration: underline;
    text-decoration-color: rgb(0, 110, 230);
    background-color: rgb(0, 110, 230, 0.2);
    color: rgb(0, 110, 230);
    cursor: pointer;
    font-weight: 400;



}
.user-for-review{
    text-decoration: underline;
    text-decoration-color: rgb(255, 0, 0);
    background-color: rgba(255, 0, 0, 0.2);
    color: rgb(255, 0, 0);
    cursor: pointer;
    font-weight: 400;
}
.user-for-review-positive{
    text-decoration: underline;
    text-decoration-color: rgb(48, 230, 48);
    background-color: rgb(48, 230, 48, 0.2);
    color: rgb(48, 230, 48);
    cursor: pointer;
    font-weight: 400;
}
.user-for-review:active{
    transform: scale(0.98);
}
.interview-video{
    position: absolute;
    bottom: 30px;
    right: 20px;
    border-radius: 10px;
    overflow: hidden;
}

.user-dialogue-general{
    padding: 5px;
}
.transcipt-container{
    width: 40%;


    height: calc(100vh - 30px);
}

.transcipt-container::-webkit-scrollbar {
    display: none;
}

.sidecoach-bottom{
    font-size: 0.9em;
    color: #aaa;
    margin-top: 20px;
    text-align: center;
}

.dialogue-content{
    line-height: 2.5em;
    color: #aaa;
    font-weight: 300;
}
.dialogue-container{
    margin-bottom: 20px;
}

.dialogue-role-assistant{
    color: rgba(222, 193, 253);
}

.dialogue-role{
    margin-right: 10px;
}

.review-loading-holder {
    width: 600px;
  
    text-align: center;
    justify-content: center;
  
  }
  
  .review-loading-holder-inner{
  
    display: flex;
  }
  
  .review-loading {
  
    background-image: url("../assets/loader.svg");
    height: 200px;
    background-size: cover;
    width: 200px;
    margin-left: 200px;
    justify-content: center;
    display: flex;
  
  }
  .review-uploader-holder{
  width: 300px;
  background-color: #ddd;
  height: 5px;
  
  margin-bottom: 30px;
  
  overflow: hidden;
  
  }
  
  
  
  .review-loading-tech {
    color: #000;
    font-size: 0.9em;
  }
  .review-loading-container{

    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
  }
  .review-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 40px);
    flex-direction: column;
  }
  
  .review-uploader-bar{
    height: 100%;
    background-color: black;
    /* width: 200px; */

  }

  .rev-uh-right{
    border-radius: 0px 20px 20px 0px;
  }



  .review-loading-tech-sub{

    width: fit-content;

  }

  .rev-play{
    background-image: url("../assets/play.png");
    background-size: cover;
  }

  .rev-row{
    padding: 20px;
    border-radius: 5px;
    overflow: hidden;
  }

  .even-rev{
    background-color: #f3f6f8;
    height: 40px;
    border-radius: 10px;
  }
  
  .odd-rev{
    height: 40px;
  }
  
  .review-rows-container{
    width: 90vw;
    margin-left:5vw ;
    position: absolute;
    left: 0px;
    /* border: solid #eee 1px; */
    
  
  }
  
  
  
  .last-table-item{
    text-align: right;
  }
  
  .th-icon-word{
    display: flex;
    width: 100px;
  
    justify-content: center;
  }
  .th-word{
    font-size: 1em;
    font-weight: 300;
    text-align: left;
  }
  
  .text-right{
    text-align: right;
  }
  
  .thead-revs{
    margin-bottom: 20px;
    border-top: solid 1.5px #eee;
  }
  
  
  
  .reviews-ttl{
    padding: 20px;
    font-weight: 500;
  }
  
  .rev-row-datetime{
    font-size: 0.9em;
    color: #777;
  }
  
  .rev-row-duration{
    font-size: 0.9em;
    color: #777;
  }
  
  .rev-row-insights{
    color: black;
    font-weight: 300;
  }
  
  .rev-row-ttl{
    font-size: 0.9em;
    font-weight: 400;
  }

  .reviews-header{

    font-size: 1.8em;
    font-weight: 600;
    color: #020014;
  }


  .feedback-bg{
    z-index: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
  }

  .not-phone-hide{
    display: none;
  }


  .unlock-ai-coach{
    background-color: #703CF0;
    padding: 10px;
    color: white;
    letter-spacing: 0.1mm;
    margin-top: 20px;
    margin-bottom: 40px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);
    /* display: none; */
  }

  .review-loading-banner{
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    bottom: 40px;
    left: calc(50vw - 300px);
  }
 

/* media query screen less than 600px */

@media (min-width: 1200px) {

  .big-screen-hide{
    display: none;
  }

}

@media (max-width: 1200px) {
  .sidecoach{
    display: none;
    /* display: flex; */

  }

  .transcipt-container{
    width: calc(90vw - 400px);
    margin-left: 5vw;
    overflow-y: scroll;
}



}


@media (max-width: 900px) {

  .unlock-ai-coach{
    display: block;
  }

  .interview-video{
    display: none;
  }

  .feedback-box{
    z-index: 1;
  }

  .transcipt-container{
    width: calc(90vw);
    margin-left: 5vw;
    overflow-y: scroll;
}

.not-phone-hide{
  display: block;
}
.phone-hide{
  display: none;
}

.feedback-box{

  position: fixed;
  left: 5vw;
  top: 80px;
  width: 90vw;
  height: 80vh;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);
  padding: 20px;


  flex-direction: column;
  

}




}



.new-sec-dashed{
  border-top: dashed 0.5px #703CF0;
  flex-grow: 1;

}

.new-section-word{
  font-size: 0.8em;
  color: #703CF0;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  margin-top: -0.8em;
  margin-left: 10px;
  margin-right: 10px;
}
.feedback-sections{
  width: 50%;

}



.interview-lhs{
  width: 250px;
  justify-content: center;

}



.transcript-title {
  font-size: 1.5em;
}

.indicator-gradient{
  width: 30px;
  height: 380px;
  /* linear gradient starts at red goes through orange and yellow to green and then finishes at blue-purple */
  background: linear-gradient(to bottom, red, orange, yellow, green, blue, indigo, violet);
}