.pp-menu{
    position: absolute;
    width: 200px;
    background-color: white;
    border-radius: 5px;
    border: solid #eee 1px;
    z-index: 1;
    
    margin-left: -170px;
    margin-top: 35px;
    padding: 10px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  
  }

  .pp-ur-inp{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    background-color: green;
    width: 500px;
    height: 50px;
  }

  .pp-menu-item{
    color: black;
    padding:5px;
    font-size: 0.9em;
    color: #777;
  }

  .sub-tier{
    margin-bottom: 10px;
    font-size: 0.9em;
    color: rgba(188, 19, 254, 1);
    background-color: rgba(188, 19, 254, 0.3);
    /* border: solid rgba(188, 19, 254, 1) 1px; */
    border-radius: 5px;
    padding:3px;
    width: 60px;
    text-align: center;
  }
  

  .pp-menu-hdr-flex{
    color: black;
    font-size: 1em;
    border-bottom: solid #ccc 1px;;
    padding: 5px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .pp-menu-close{
    font-size: 1.3em;
  }


  @media (max-width: 500px) {
    .pp-menu{
        top: 0px;
        left: 0px;
        margin-left: 0px;
        margin-top: 0px;
        border-radius: 0px;
        width: 100vw;
        height: 100vh;
    }
    .pp-menu-item{
        font-size: 1.2em;
        padding: 8px;
    }

    .sub-tier{
        margin-bottom: 10px;
        font-size: 1.2em;
        color: rgba(188, 19, 254, 1);
        background-color: rgba(188, 19, 254, 0.3);
        /* border: solid rgba(188, 19, 254, 1) 1px; */
        border-radius: 5px;
        padding:3px;
        width: 60px;
        text-align: center;
      }

      .pp-menu-hdr{

        font-size: 1.3em;
      
      }
  }
