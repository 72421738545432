.yc-setup-bg{
    background-color: #F5F5EE;
    /* max-height: 100vh; */
    overflow: scroll;
    
}

.yc-setup-text-container{
    color: #f60;
    margin-top: 20vh;
    margin-bottom: 10vh;
}

.yc-setup-btn{
    background-color: #FDA942;
    color: white;
    font-size: 1.0em;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 150px;
    text-align: center;
    border-radius: 5px;
}

.yc-setup-btn:hover{
    background-color: #f60;
}

.yc-setup-btn:active{
    transform: scale(0.97);
}

.welcome-to{
    font-size: 3em;
}

.please-select{
    font-size: 1.8em;
    font-weight: 300;
    /* color: #aaa; */
}
.orange-selected-border{
    border: green solid 2px;
}



.yc-setup-grid{
    display: grid;
    /* background-color: green; */
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    width: 80vw;
    margin-left: 10vw;

    grid-gap: 40px;
    justify-content: center;
    /* background-color: green; */




}