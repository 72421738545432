.templates-page-container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.if-template-holder{
    overflow: hidden;
}

.templates-page {
    height: calc(100vh - 1px);
    max-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
  }

  .template-page-table-container{
    flex-grow: 1;
    overflow: hidden;


  }